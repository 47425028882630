import { render, staticRenderFns } from "./EditGideonCardNote.vue?vue&type=template&id=110185eb&scoped=true&"
import script from "./EditGideonCardNote.vue?vue&type=script&lang=js&"
export * from "./EditGideonCardNote.vue?vue&type=script&lang=js&"
import style0 from "./EditGideonCardNote.vue?vue&type=style&index=0&id=110185eb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110185eb",
  null
  
)

export default component.exports